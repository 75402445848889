// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { withI18n, withI18nProps } from "@lingui/react";
import { t } from "@lingui/macro";

// Import types.
import SupportedLanguage from "types/enums/SupportedLanguage";
import PortalState from "types/store";
import User from "types/common/User";
import ScreenSettings from "types/common/ScreenSettings";
import { getScreenDataForId } from "assets/data/screens";
import { FormatOptions, SearchResult } from "../../../../engine/types";

// Import components.
import { Typography, MenuItem } from "@mui/material";
import ImageWrapper from "components/common/ImageWrapper";

// Import icons.
import WebAssetIcon from "@mui/icons-material/WebAsset";
import StarIcon from "@mui/icons-material/Star";

// Import formatters.
import HighlightFormatter from "utils/formatters/Highlight";

interface STATE_PROPS {
    currentUser: User | null;
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    data: SearchResult;

    formatOptions?: FormatOptions | null;

    onResultClick: (result: SearchResult) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, withI18nProps {}

const mapStateToProps = (state: PortalState) => {
    return {
        currentUser: state.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {};
};

class ScreenResult extends React.PureComponent<PROPS> {
    render() {
        const { i18n, classes, currentUser, data, formatOptions } = this.props;

        if (!data.data) return null;

        const { systemEnableValue, search, isFavorite } = data.data;

        // Get screen data
        const screenData = getScreenDataForId(data.targetPath);
        if (!screenData) return null;

        let screenTitle = screenData?.title(i18n);

        let screenPath: any = null;

        // Determine screenPath
        if (systemEnableValue) {
            screenPath = currentUser?.preferredLanguage === SupportedLanguage.FRENCH ? (data.data as ScreenSettings).namexFr : (data.data as ScreenSettings).namexEn;

            if (screenPath) {
                if (data.targetPath.startsWith("/app")) screenPath = i18n._(t`APP >`) + " " + screenPath;
                if (data.targetPath.startsWith("/user")) screenPath = i18n._(t`USER >`) + " " + screenPath;
            }
        } else if (search && search.title) {
            screenPath = search.title(i18n);
        }

        // If both screenPath and screenTitle are missing, return null
        if (!screenPath || !screenTitle) {
            return null;
        }

        return (
            <MenuItem component={"div"} className={classes.result} onClick={() => this.props.onResultClick(data)}>
                <ImageWrapper style={{ width: "2.25em", height: "2.25em", marginRight: "0.625em" }} src={<WebAssetIcon />} />

                <div className={classes.resultInfo}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography style={{ overflow: "hidden", whiteSpace: "break-spaces", wordBreak: "break-all" }} noWrap>
                            <b>{HighlightFormatter.highlight(`${screenTitle}`, data.searchTerm, formatOptions?.highlight)}</b>
                        </Typography>
                        {isFavorite && <StarIcon style={{ color: "var(--icon-button-default-color)" }} />}
                    </div>

                    <span style={{ display: "flex", alignItems: "center", fontSize: "0.875em", overflow: "hidden" }}>
                        <b>{HighlightFormatter.highlight(`${screenPath}`, data.searchTerm, formatOptions?.highlight)}</b>
                    </span>
                </div>
            </MenuItem>
        );
    }
}

const styles = () =>
    createStyles({
        result: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            padding: "0.625em",

            "&:not(:first-of-type)": {
                borderTopStyle: "solid",
                borderTopWidth: "0.0625em",
            },

            "&:hover": {
                backgroundColor: "var(--popup-menu-hover-background-color, inherit)",
                color: "var(--popup-menu-hover-color, inherit)",
            },
        },
        resultInfo: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            overflow: "hidden",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withI18n()(withStyles(styles)(ScreenResult)));
