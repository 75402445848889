// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Theme, ClickAwayListener } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withI18n, withI18nProps } from "@lingui/react";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import PortalPrivilege from "types/common/PortalPrivilege";
import ScreenSettings from "types/common/ScreenSettings";
import PortalRouteDefinition from "types/common/PortalRouteDefinition";

// Import components.
import Header from "./components/Header";
import Options from "./components/Options";
import Queries from "./components/Queries";
import Results from "./components/Results";

// Import the search engine.
import { FormatOptions, Query, QueryOptions, SearchResult } from "./engine/types";
import SearchEngine from "./engine";

// Import utilities.
import { determineNavigationContext } from "./engine/utils";

interface STATE_PROPS {
    session: Session;
    currentUser: User | null;
    availableCompanies: TeamInfo[];
    availableApps: AppInfo[];
    availablePrivileges: PortalPrivilege[];
    screenSettings: ScreenSettings[];
    favorites: string[];
}
interface DISPATCH_PROPS {
    callMultipleSagas: (sagas: { type: string; payload: any }[]) => void;
}
interface OWN_PROPS {
    navigatorOpen: boolean;
    navigatorPinned: boolean;
    profileOpen: boolean;
    profilePinned: boolean;
    onToggleNavigatorOpen: (open?: boolean) => void;
    onToggleNavigatorPinned: (pinned?: boolean) => void;
    onToggleProfileOpen: (open?: boolean) => void;
    onToggleProfilePinned: (pinned?: boolean) => void;

    open: boolean;
    query: string;
    force: boolean;
    queryOptions: QueryOptions;
    formatOptions: FormatOptions;
    definitions: PortalRouteDefinition[];

    onClose: () => void;
    onQueryOptionChange: (name: string, value: any) => void;
    onFormatOptionChange: (name: string, value: any) => void;
    onSearchComplete: () => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps, withI18nProps {}

interface STATE {
    engine: SearchEngine;
    currentQueries: Query[] | null;
    currentResults: SearchResult[] | null;
    optionsOpen: boolean;
    isBusy: boolean;
}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
        currentUser: state.currentUser,
        availableCompanies: state.availableCompanies,
        availableApps: state.availableApps,
        availablePrivileges: state.availablePrivileges,
        screenSettings: state.screenSettings,
        favorites: state.favorites,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        callMultipleSagas: (sagas: { type: string; payload: any }[]) => dispatch({ type: "utility.callMultipleSagas", payload: sagas }),
    };
};

class SearchResults extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        engine: new SearchEngine(),
        currentQueries: null,
        currentResults: null,
        optionsOpen: false,
        isBusy: false,
    };

    componentDidMount() {
        const { i18n, location, session, currentUser, availableCompanies, availableApps, availablePrivileges, screenSettings, definitions, queryOptions, favorites } = this.props;
        const { engine } = this.state;

        engine.configure({
            i18n: i18n,
            session: session,
            currentUser: currentUser,
            context: determineNavigationContext(location.pathname),
            availableCompanies: availableCompanies,
            availableApps: availableApps,
            availablePrivileges: availablePrivileges,
            screenSettings: screenSettings,
            definitions: definitions,
            options: queryOptions,
            favorites: favorites,
        });

        this.updateSearchResults();
    }

    componentDidUpdate(prevProps: PROPS) {
        if (
            prevProps.i18n !== this.props.i18n ||
            prevProps.session !== this.props.session ||
            prevProps.currentUser !== this.props.currentUser ||
            prevProps.location.pathname !== this.props.location.pathname ||
            prevProps.availableCompanies !== this.props.availableCompanies ||
            prevProps.availableApps !== this.props.availableApps ||
            prevProps.availablePrivileges !== this.props.availablePrivileges ||
            prevProps.screenSettings !== this.props.screenSettings ||
            prevProps.definitions !== this.props.definitions ||
            prevProps.queryOptions !== this.props.queryOptions ||
            prevProps.favorites !== this.props.favorites
        ) {
            this.state.engine.configure({
                i18n: this.props.i18n,
                session: this.props.session,
                currentUser: this.props.currentUser,
                context: determineNavigationContext(this.props.location.pathname),
                availableCompanies: this.props.availableCompanies,
                availableApps: this.props.availableApps,
                availablePrivileges: this.props.availablePrivileges,
                screenSettings: this.props.screenSettings,
                definitions: this.props.definitions,
                options: this.props.queryOptions,
                favorites: this.props.favorites,
            });
        }

        if (this.requiresRefresh(prevProps, this.props)) {
            this.updateSearchResults();
        }
    }

    requiresRefresh = (prevProps: PROPS, newProps: PROPS) => {
        // If the query has changed (or the force flag is true) then we need to re-execute the query.
        if (prevProps.query !== newProps.query || (!prevProps.force && newProps.force)) return true;

        // If the query options have changed then we need to re-execute the query.
        if (prevProps.queryOptions !== newProps.queryOptions) return true;

        // If the navigation context has changed then we MIGHT need to re-execute the query.
        // TODO: Figure out under what circumstances we force a re-evaluation based on context change.
        if (determineNavigationContext(prevProps.location.pathname) !== determineNavigationContext(newProps.location.pathname)) return true;

        return false;
    };

    updateSearchResults = () => {
        const { query } = this.props;
        const { engine } = this.state;

        this.setState({ currentQueries: null, currentResults: null, isBusy: true }, () => {
            if (query.trim().length > 0) {
                engine
                    .execute(query)
                    .then(({ queries, results }) => {
                        this.setState({ currentQueries: queries, currentResults: results, isBusy: false });
                    })
                    .catch((error) => {
                        if (error.name === "AbortError") {
                            console.debug("Search Engine - Query Cancelled", query);
                        } else {
                            console.warn("Search Engine - Unexpected Error", error);

                            this.setState({ isBusy: false });
                        }
                    });
            } else {
                this.setState({ isBusy: false });
            }

            this.props.onSearchComplete();
        });
    };

    handleToggleOptionsOpen = () => {
        this.setState({ optionsOpen: !this.state.optionsOpen });
    };

    handleResultClicked = (result: SearchResult) => {
        const { i18n, session, history, location } = this.props;

        // When a result item is clicked, we automatically close the search results prior to performing the actual navigation.
        this.props.onClose();

        window.setTimeout(() => {
            // It is possible that the result item refers to a company or app that is NOT currently selected.
            // In this scenario we want to set the selected company (or company alias if logged in as a SUPER user) and the selected app.
            // This involves invoking the appropriate saga(s) and passing the target path and args to the saga so the route change happens
            // once everything is properly set.

            let requiresCompanyIdChange = false;
            let requiresCompanyIdAliasChange = false;
            let requiresAppIdChange = false;

            if (result.data && (result.data.companyId || result.data.appId)) {
                if (session.isSuper) {
                    if (session.companyIdAlias !== result.data.companyId) {
                        requiresCompanyIdAliasChange = true;
                    }
                } else {
                    if (session.companyId !== result.data.companyId) {
                        requiresCompanyIdChange = true;
                    }
                }

                if (result.data.appId && session.appId !== result.data.appId) {
                    requiresAppIdChange = true;
                }
            }

            const sagasToCall: { type: string; payload: any }[] = [];

            if (requiresCompanyIdChange) {
                sagasToCall.push({ type: "company.setCompanyId", payload: { i18n: i18n, companyId: result.data?.companyId } });

                if (requiresAppIdChange) {
                    sagasToCall.push({ type: "app.setAppId", payload: { appId: result.data?.appId } });
                }
            } else {
                if (requiresCompanyIdAliasChange) {
                    sagasToCall.push({ type: "company.setCompanyIdAlias", payload: { companyIdAlias: result.data?.companyId, appId: requiresAppIdChange ? result.data?.appId : undefined } });
                } else {
                    if (requiresAppIdChange) {
                        sagasToCall.push({ type: "app.setAppId", payload: { appId: result.data?.appId } });
                    }
                }
            }

            if (sagasToCall.length > 0) {
                sagasToCall[sagasToCall.length - 1].payload = { ...sagasToCall[sagasToCall.length - 1].payload, path: result.targetPath, state: result.targetState, history: history };

                console.log("Calling Multiple Sagas", sagasToCall);

                this.props.callMultipleSagas(sagasToCall);
            } else {
                if (location.pathname !== result.targetPath) {
                    history.push({ pathname: result.targetPath, state: result.targetState });
                } else {
                    history.replace({ pathname: result.targetPath, state: result.targetState });
                }
            }
        }, 250);
    };

    handleClickAway = () => {
        const { open } = this.props;

        if (open) {
            this.props.onClose();
        }
    };

    render() {
        const { classes, queryOptions, formatOptions, availableApps, session } = this.props;
        const { optionsOpen, currentQueries, currentResults, isBusy } = this.state;

        return (
            <ClickAwayListener onClickAway={this.handleClickAway}>
                <div className={classes.root}>
                    <Header results={currentResults} onClose={this.props.onClose} onToggleOptions={this.handleToggleOptionsOpen} />

                    {optionsOpen && <Options queryOptions={queryOptions} formatOptions={formatOptions} onQueryOptionChange={this.props.onQueryOptionChange} onFormatOptionChange={this.props.onFormatOptionChange} disabled={isBusy} />}

                    {formatOptions.queries && <Queries queries={currentQueries} />}

                    <Results availableApps={availableApps} session={session} queries={currentQueries} results={currentResults} formatOptions={formatOptions} onResultClick={this.handleResultClicked} isBusy={isBusy} />
                </div>
            </ClickAwayListener>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            width: "100%",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "var(--popup-menu-background-color, inherit)",
            color: "var(--popup-menu-color, inherit)",
            borderColor: "var(--popup-menu-border-color, inherit)",

            overflow: "hidden",

            fontSize: "0.875em",

            "& .MuiMenuItem-root, & .MuiTypography-root": {
                fontSize: "inherit",
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withRouter(withI18n()(withStyles(styles)(SearchResults))));
