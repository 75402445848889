// Import libraries.
import React from "react";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { withI18n, withI18nProps } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

// Import types.
import { FormatOptions, Query, PluginName, SearchResult } from "../../engine/types";
import AppInfo from "types/models/AppInfo";
import Session from "types/common/Session";
import CompanyStatus from "types/enums/CompanyStatus";

// Import components.
import { Link, Typography } from "@mui/material";
import LoadingProgress from "components/common/widgets/LoadingProgress";
import Tabs from "components/common/tabs";
import TeamResult from "./components/TeamResult";
import AppResult from "./components/AppResult";
import UserResult from "./components/UserResult";
import ScreenResult from "./components/ScreenResult";

interface OWN_PROPS {
    queries: Query[] | null;

    results: SearchResult[] | null;

    formatOptions?: FormatOptions | null;
    availableApps: AppInfo[];

    isBusy: boolean;
    session: Session;
    onResultClick: (result: SearchResult) => void;
}

interface PROPS extends OWN_PROPS, WithStyles<typeof styles>, withI18nProps {}

class Results extends React.PureComponent<PROPS> {
    getResultsCountByCategory = (type?: PluginName) => {
        const { results } = this.props;

        if (results == null) return 0;

        if (!type) return results.length;

        const filteredResults = results.filter((result) => result.type === type);

        return filteredResults.length;
    };

    renderResultsByCategory = (type?: PluginName) => {
        const { results, formatOptions, onResultClick } = this.props;

        if (results == null) return null;

        let filteredResults = results.filter((result) => result.type === type);

        if (type === PluginName.TEAM) {
            const liveAppsTeam = filteredResults.filter((team) => team?.data?.companyStatus === CompanyStatus.BILLING);
            filteredResults = [...liveAppsTeam, ...filteredResults.filter((team) => team?.data?.companyStatus !== CompanyStatus.BILLING)];
        }

        if (filteredResults.length === 0) return null;

        return (
            <>
                {filteredResults.map((item, idx) => {
                    switch (type) {
                        case PluginName.APP:
                            return <AppResult key={"app-result-" + idx} data={item} formatOptions={formatOptions} onResultClick={onResultClick} />;
                        case PluginName.TEAM:
                            return <TeamResult key={"team-result-" + idx} data={item} formatOptions={formatOptions} onResultClick={onResultClick} />;
                        case PluginName.PLAYER:
                            return <UserResult key={"user-result-" + idx} data={item} formatOptions={formatOptions} onResultClick={onResultClick} />;
                        case PluginName.SCREEN:
                            return <ScreenResult key={"screen-result-" + idx} data={item} formatOptions={formatOptions} onResultClick={onResultClick} />;
                        default:
                            return null;
                    }
                })}
            </>
        );
    };

    renderAllResults = () => {
        const { classes, results, availableApps, i18n } = this.props;

        const appCount = this.getResultsCountByCategory(PluginName.APP);
        const teamCount = this.getResultsCountByCategory(PluginName.TEAM);
        const playerCount = this.getResultsCountByCategory(PluginName.PLAYER);
        const screenCount = this.getResultsCountByCategory(PluginName.SCREEN);

        const noResults = (
            <Typography style={{ flex: "0 0 auto", marginLeft: "auto", marginRight: "auto" }}>
                <Trans>No items found for supplied query.</Trans>
            </Typography>
        );
        const appId = results?.find((result) => result.type === PluginName.PLAYER)?.data?.appId || "";
        const appName = availableApps?.find((app) => app.appId === appId)?.appName || "";
        return (
            <>
                <Typography className={classes.title}>
                    <Trans>Application ({appCount})</Trans>
                </Typography>
                {appCount > 0 ? this.renderResultsByCategory(PluginName.APP) : noResults}

                <Typography className={classes.title}>
                    <Trans>Team ({teamCount})</Trans>
                </Typography>
                {teamCount > 0 ? this.renderResultsByCategory(PluginName.TEAM) : noResults}

                <Typography className={classes.title}>
                    <Trans>
                        User ({playerCount}) {playerCount ? i18n._(` for app ${appName} (${appId})`) : ""}
                    </Trans>
                </Typography>
                {playerCount > 0 ? this.renderResultsByCategory(PluginName.PLAYER) : noResults}

                <Typography className={classes.title}>
                    <Trans>Screen ({screenCount})</Trans>
                </Typography>
                {screenCount > 0 ? this.renderResultsByCategory(PluginName.SCREEN) : noResults}
            </>
        );
    };

    render() {
        const { classes, results, isBusy, queries, availableApps, session, i18n } = this.props;

        const appCount = this.getResultsCountByCategory(PluginName.APP);
        const teamCount = this.getResultsCountByCategory(PluginName.TEAM);
        const playerCount = this.getResultsCountByCategory(PluginName.PLAYER);
        const screenCount = this.getResultsCountByCategory(PluginName.SCREEN);

        const { appId } = session;
        const appName = availableApps.find((app) => app.appId === appId)?.appName || "";

        const noResults = (
            <Typography style={{ flex: "0 0 auto", margin: "auto" }}>
                <Trans>No items found for supplied query.</Trans>
            </Typography>
        );

        const noQueries = (
            <Typography style={{ flex: "0 0 auto", margin: "auto", textAlign: "center" }}>
                <Trans>
                    Search for apps, teams, users, and screens.
                    <br />
                    Enter text freeform, or with a keyword for more focused searches.
                    <br />
                    <br />
                    User Searches are limited to the Current app:
                    <br />
                    {appId ? `${appName} (${appId})` : i18n._(t`No App Selected`)}
                    <br />
                    <br />
                    For more information, go
                    <Link data-id={"Knowledge-base"} href={"https://help.getbraincloud.com/en/articles/6949692-smart-search"} target={"_blank"} style={{ marginLeft: "0.25em" }}>
                        <Trans>here.</Trans>
                    </Link>
                </Trans>
            </Typography>
        );

        return (
            <div className={classes.root}>
                {isBusy && <LoadingProgress type={"linear"} label={<Trans>Searching...</Trans>} />}

                {!isBusy && (queries == null || queries.length === 0) && noQueries}

                {!isBusy && queries && (results == null || results.length === 0) && noResults}

                {!isBusy && results != null && results.length > 0 && (
                    <Tabs
                        orientation={"horizontal"}
                        wrapped
                        className={classes.tabs}
                        configs={[
                            {
                                id: "all",
                                label: <Trans>All</Trans>,
                                component: <div className={classes.tabContent}>{this.renderAllResults()}</div>,
                            },

                            {
                                id: PluginName.APP,
                                label: <Trans>Application ({appCount})</Trans>,
                                component: appCount > 0 ? <div className={classes.tabContent}>{this.renderResultsByCategory(PluginName.APP)}</div> : noResults,
                            },
                            {
                                id: PluginName.TEAM,
                                label: <Trans>Team ({teamCount})</Trans>,
                                component: teamCount > 0 ? <div className={classes.tabContent}>{this.renderResultsByCategory(PluginName.TEAM)}</div> : noResults,
                            },
                            {
                                id: PluginName.PLAYER,
                                label: <Trans>User ({playerCount})</Trans>,
                                component: playerCount > 0 ? <div className={classes.tabContent}>{this.renderResultsByCategory(PluginName.PLAYER)}</div> : noResults,
                            },
                            {
                                id: PluginName.SCREEN,
                                label: <Trans>Screen ({screenCount})</Trans>,
                                component: screenCount > 0 ? <div className={classes.tabContent}>{this.renderResultsByCategory(PluginName.SCREEN)}</div> : noResults,
                            },
                        ]}
                    />
                )}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",
        },
        tabs: {
            "& .MuiTabs-root": {
                minHeight: "unset",
                maxHeight: "unset",

                padding: "0 1em",

                "& .MuiTab-root": {
                    minHeight: "unset",
                    maxHeight: "unset",

                    minWidth: "unset",
                    maxWidth: "unset",
                },
            },
        },
        tabContent: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",

            overflowX: "hidden",
            overflowY: "auto",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
        title: {
            padding: "0 1em",
            backgroundColor: "var(--secondary-border-color, inherit)",
        },
    });

export default withI18n()(withStyles(styles)(Results));
